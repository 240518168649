import React from "react"
import { Link } from "gatsby"
import footerStyles from "../../styles/footer/footer.module.scss"

const Footer = () => (
  <>
    <div id="footer" className={footerStyles.footer}>
      <div className={footerStyles.footerLeft}>
        <span>Padre Fiorenzo Fornara Erbetta</span>
        <span className={footerStyles.text}>
          <a
            href="mailto:info@santuariodiboca.it"
            target="_blank"
            rel="noreferrer"
          >
            info@santuariodiboca.it
          </a>
        </span>
        <span className={footerStyles.text}>
          <a href="tel:+39032287142" target="_blank" rel="noreferrer">
            tel. 0322 87142
          </a>
        </span>
        <span className={footerStyles.text}>
          <a
            href="https://wa.me/393495315439?text=Buongiorno%20vi%20contatto%20dal%20sito%20per%20informazioni"
            target="_blank"
            rel="noreferrer"
          >
            whatsapp 349 531 5439
          </a>
        </span>
        <span className={footerStyles.text}>
          <a
            href="https://goo.gl/maps/k5vUh3beq2Z7hRbcA"
            target="_blank"
            rel="noreferrer"
          >
            via S.Francesco d’Assisi 31 - 28010 Boca (NO)
          </a>
        </span>
      </div>

      <div className={footerStyles.footerRight}>
        <span>DONAZIONI AL SANTUARIO</span>
        <p>
          Potete fare donazioni al Santuario del SS Crocifisso di Boca tramite
          bonifico bancario.
        </p>
        <p>IBAN IT27F0306909606100000018354</p>
      </div>
    </div>

    <div className={footerStyles.legal}>
      <Link to="/privacy-policy">
        <span>privacy policy</span>
      </Link>
      <Link to="/cookie-policy">
        <span>cookie policy</span>
      </Link>
    </div>

    <p
      className={footerStyles.copy}
    >{`© ${new Date().getFullYear()} Santuario del SS Crocifisso di Boca - P.IVA 01025510031 - Tutti i diritti riservati`}</p>
  </>
)

export default Footer
