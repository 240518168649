import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useStaticQuery, graphql } from "gatsby"
import Markdown from "react-markdown"
import bannerStyles from "../../styles/common/banner.module.scss"
import headerStyles from "../../styles/header/header.module.scss"

const Banner = () => {
  const data = useStaticQuery(graphql`
    query BannerQuery {
      strapiBanner {
        pubblicato
        testo
      }
    }
  `)

  return (
    <>
    {/* <AnchorLink to="/#festa" stripHash activeClassName={headerStyles.active}> */}
    <div
      style={
        data.strapiBanner.pubblicato
          ? { display: "block" }
          : { display: "none" }
      }
    >
      <Markdown className={bannerStyles.content}>
        {data.strapiBanner.testo}
      </Markdown>
    </div>
    {/* </AnchorLink> */}
        </>
  )
}

export default Banner
