import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import headerStyles from "../../styles/header/header.module.scss"
import Banner from "../common/banner"

const Header = ({ menuLinks, location }) => (
  <>
    <nav className={headerStyles.head}>
      <Link to="/">
        Santuario del <br />
        SS Crocifisso di Boca
      </Link>
    </nav>
    <ul className={headerStyles.navigation}>
      <Link to="/" activeClassName={headerStyles.active}>
        Home
      </Link>
      <Link to="/storia-del-santuario" activeClassName={headerStyles.active}>
        Storia del Santuario
      </Link>

      <Link
        to="/commenti-alle-letture-domenicali"
        activeClassName={headerStyles.active}
      >
        Commenti alle Letture Domenicali
      </Link>

      <Link
        to="/meditazioni-e-approfondimenti"
        activeClassName={headerStyles.active}
      >
        Meditazioni e Approfondimenti
      </Link>

      <AnchorLink to="/#orari" stripHash activeClassName={headerStyles.active}>
        Orari
      </AnchorLink>

      <AnchorLink
        to={`${location.pathname}#footer`}
        stripHash
        activeClassName={headerStyles.active}
      >
        Contatti
      </AnchorLink>
    </ul>
    {location.pathname === "/" && <Banner />}
  </>
)

export default Header
